import React, { Component } from 'react';
import { Form, Row, Col, FormControl, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Modal, Select } from 'antd';
import config from 'react-global-configuration';
import Image from "./image.component";
import axios from 'axios';
import jwt from 'jsonwebtoken';

const { Option } = Select;
function handleCurrency(value) {
  //console.log(`selected ${value}`);

  localStorage.setItem('ecurrencyid', JSON.parse(value).CurrencyID);
  localStorage.setItem('ecurrencyratio', 1 * JSON.parse(value).CurrencyRate);
  localStorage.setItem('ecurrencyCode', JSON.parse(value).CurrencySymbol);
  localStorage.setItem('ecurrencythreeCode', JSON.parse(value).CurrencyCode);
  localStorage.setItem('dcurrency', "true");
  window.location.reload();
}
function getFaviconEl() {
  return document.getElementById("favicon");
}
export default class header extends Component {
  constructor(props) {
    super(props);
    this.state = { company: [], categories: [], orgcurrency: [], defCCode: localStorage.getItem('ecurrencythreeCode') || 'GBP', defCSymbl: localStorage.getItem('ecurrencyCode') || '£' }
  }
  componentDidMount() {

    const favicon = getFaviconEl(); // Accessing favicon element
    document.title = config.get('companyPageTitle')
    favicon.href = 'https://cdn.neurolage.com/retailpacx/'+config.get('CDNPath').replace(/ /g, '').replace('&', '').replace(/(^\&)|,/g, '').toLowerCase() + '/'+ config.get('favicon');

    const timestamp = Math.floor(Date.now() / 1000) + 1000;
    var payload = {
      "aud": config.get('SalesjwtAud'),
      "exp": timestamp,
      "iss": config.get('SalesjwtIss')
    };

    var signOptions = {
      "algorithm": "RS256"
    };

    var privateKey = config.get('SalesprivateKey');
    var token = jwt.sign(payload, privateKey, signOptions);


    const cachedSetting = sessionStorage.getItem('setting');
    //if (!cachedSetting) {
    axios.get(config.get('API_salesregistry') + 'setting/' + config.get('OrganisationID') + '/26', { headers: { "Authorization": 'Bearer ' + token } })
      .then(response => {
        if (response.data.Result[0].IPLookup) {
          const cachedipresponse = localStorage.getItem('ipresponse');
          if (!cachedipresponse) {
            axios.get("https://api.ipstack.com/check?access_key=5e7152f63b2d02398b551ee66730bbfa")
              .then(ipresponse => {
                localStorage.setItem('ipresponse', JSON.stringify(ipresponse.data));
              })
          }
        }
        sessionStorage.setItem('setting', JSON.stringify(response.data.Result));
      })
      .catch((error) => {
        console.log(error);
      })
 

    axios.get(config.get('API_salesregistry') + 'currencies/' + config.get('OrganisationID') + '/26', { headers: { "Authorization": 'Bearer ' + token } })
      .then(response => {
        // console.log('response')
        this.setState({ orgcurrency: response.data.Result });
        this.state.orgcurrency.map(oc => {
          // console.log(oc);

          if (JSON.parse(localStorage.getItem('ipresponse')).currency.code==oc.CurrencyCode && !localStorage.getItem('dcurrency')) {
            localStorage.setItem('ecurrencyid', oc.CurrencyID);
            localStorage.setItem('ecurrencyratio', 1 * oc.CurrencyRate);
            localStorage.setItem('ecurrencyCode', oc.CurrencySymbol);
            localStorage.setItem('ecurrencythreeCode', oc.CurrencyCode);
            return true;
            
          }else if (oc.IsBaseCurrency == true && !localStorage.getItem('dcurrency')) {
              localStorage.setItem('ecurrencyid', oc.CurrencyID);
              localStorage.setItem('ecurrencyratio', 1 * oc.CurrencyRate);
              localStorage.setItem('ecurrencyCode', oc.CurrencySymbol);
              localStorage.setItem('ecurrencythreeCode', oc.CurrencyCode);
              return true;
            }

        })
      })
      .catch((error) => {
        console.log(error);
      })

 

  }


  searchcNow = (event) => {
    event.preventDefault();

    if (document.getElementById("search").value) {

      window.location.href = "/search/" + document.getElementById("search").value
    } else {


      let secondsToGo = 5;
      const modal = Modal.warning({
        title: "Invalid or empty keyword",
        content: `Please enter a keyword to search`,
      });
      const timer = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
          content: `Please enter a keyword to search`,
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(timer);
        modal.destroy();
      }, secondsToGo * 1000);
    }
  };


  render() {
    const { orgcurrency, defCCode, defCSymbl } = this.state;
    return (
      <Row className="align-items-center" key="xx">
        <Col sm={12} xs={12} className="deliverymessage">
          <marquee direction="left"><b>{config.get('DeliveryMessage')}</b></marquee>
        </Col>
        <Col sm={3} xs={12}>
          <a href="/"> <Image params={{ ItemData: config.get('companyLogo'), className: 'col-sm-12' }} /> </a>
        </Col>

        <Col sm={6} xs={8}>
          <Form onSubmit={this.searchcNow}>
            <InputGroup className="mb-3 searchbox">
              <FormControl
                placeholder="I'm looking for…"
                aria-label="I'm looking for…"
                aria-describedby="basicaddon2"
                id="search"
                name="search"

              />
              <InputGroup.Append>
                <InputGroup.Text id="basicaddon2"><a onClick={this.searchcNow} ><FontAwesomeIcon icon={faSearch} /></a></InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>

          </Form>

        </Col>
        <Col sm={1} xs={4}>

          <Select defaultValue={this.state.defCCode + "(" + this.state.defCSymbl + ")"} onChange={handleCurrency} className="select-after currencysel">

            {
              this.state.orgcurrency.map(ocd =>
                <Option value={JSON.stringify(ocd)}>{ocd.CurrencyCode} ({ocd.CurrencySymbol})</Option>

              )}



          </Select>
        </Col>
        <Col sm={2} xs={12}>


          {

            config.get('SocialMedia') ? (
              JSON.parse(config.get('SocialMedia')).map(sm =>
                <>
                  <a href={sm.URL} key={sm._id} ><img src={sm.icons} alt={sm.Media} width="32" /></a>&nbsp;&nbsp;
  </>
              )
            ) : (
                <></>
              )}


        </Col>
      </Row>
    );
  }
}