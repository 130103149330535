import React from 'react';
import {  Radio, Skeleton } from 'antd';
const CartApparel = (props) => (

   props.params.ploaded ? (
      <>
        
        
        
{/* Cart Details */}
<div className="cart-details">
        <h4>Shopping Cart</h4>
        <div className="row">
          <div className="col-sm-12">				
            <div className="header-wrapper">
              <div className="cart-header-left">
                <div className="div1">
                  <span>Product</span>
                </div>
              </div>
              <div className="cart-header-right">
                <div className="div2">
                  <span>Price</span>
                </div>
                <div className="div3">
                  <span>Quantity</span>
                </div>
                <div className="div4">
                  <span>Total</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="show-cart-count"><span>You have 4 items in your Shopping Cart</span></div>
            
            {



props.params.basketitem.length ? (
            
<>
{
                                    props.params.basketitem.map(item => (
            <div className="cart-list">
              <div className="cart-count-number"><span>Item # 1</span></div>
              <div className="cart-items">
                <div className="cart-product-name">
                  <div className="img-wrap">
                  {
                                                   item.ProductImage !== '' ?
                                                      <img src={item.ProductImage} width="80" />
                                                      : <img src="/images/noimage.jpg" alt="." width="80" />
                                                }
                  </div>
                  <div className="product-name-wrap">
                  <a href={'/product/' + item.ProductName.replace(/ /g, '-').toLowerCase() + '/' + item.ProductID} className="product-name">{item.ProductName}</a>
                    <div className="product-more-details">
                      <p>Item Code: {item.productdetails.ProductRefNo}</p>
                    </div>
                    <div className="cart-remove-item">
                      <a onClick={props.onCloseClick.bind(this, item.ProductQty, item.ProductID, item.ProductName, item, item.productdetails.MaxOnlineOrderQty, item.productdetails.ProductCategoryID, item.productdetails.offerdetails ? (item.offerdetails[0].OfferProducts.map((prt) => prt.ProductID).join(',')) : '', item.offerdetails.Offer ? (item.offerdetails[0].Offer.PromotionQty) : '', item.offerdetails.Offer ? (item.offerdetails[0].Offer.Price) : '', item.ProductPrice)}>Remove</a>
                    </div>
                  </div>
                </div>
                <div className="cart-product-details">
                  <div className="cart-product-price">
                    <div className="label-price">Price</div>
                    <div className="price-wrap">{localStorage.getItem('ecurrencyCode')} {(item.ProductPrice * localStorage.getItem('ecurrencyratio')).toFixed(2) }</div>
                  </div>
                  <div className="cart-product-quantity">
                    <div className="line-qty">
                      <div className="label-qty">Quantity</div>
                      <div className="prd-count-inner">
                        <span className="qty-sub">
                          <button  onClick={props.onQtyReduce.bind(this, item.ProductID, item.ProductName, item, item.productdetails.MaxOnlineOrderQty, item.productdetails.ProductCategoryID, item.productdetails.offerdetails ? (item.offerdetails[0].OfferProducts.map((prt) => prt.ProductID).join(',')) : '', item.offerdetails.Offer ? (item.offerdetails[0].Offer.PromotionQty) : '', item.offerdetails.Offer ? (item.offerdetails[0].Offer.Price) : '', item.ProductPrice)}>
                            <i className="fas fa-minus" />
                          </button>
                        </span>
                        <span className="qty-no">
                        <input id={'prodqty' + item.ProductID} min="0" name="form-0-quantity" value={item.ProductQty} type="number" placeholder={1} defaultValue={1} />
                        </span>
                        <span className="qty-add">
                          <button onClick={props.onQtyIncrese.bind(this, item.ProductID, item.ProductName, item, item.productdetails.MaxOnlineOrderQty, item.productdetails.ProductCategoryID, item.productdetails.offerdetails ? (item.offerdetails[0].OfferProducts.map((prt) => prt.ProductID).join(',')) : '', item.offerdetails.Offer ? (item.offerdetails[0].Offer.PromotionQty) : '', item.offerdetails.Offer ? (item.offerdetails[0].Offer.Price) : '', item.ProductPrice)}>
                            <i className="fas fa-plus" />
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="cart-product-total">
                    <div className="label-total">Total</div>
                    <div className="total-wrap">{localStorage.getItem('ecurrencyCode')} {(item.ProductQty * item.ProductPrice * localStorage.getItem('ecurrencyratio')).toFixed(2)}</div>
                  </div>
                </div>
              </div>

 

            </div>

 )
 )
}
</>

) : (
   <>
     <div className="show-cart-count"> 
         <h6>Your cart is empty</h6>
         <p>Find hundred of quality products at bargain prices everyday!</p>
         </div>

   </>

)



}
             
            
          </div>
        </div>

        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 ">


{props.params.offer != 0 ? (<>
   <div >
<figure>
<figcaption>Discount Details</figcaption>

{

props.params.basketitem.map(item => (
item.OfferAmount != 0 ? (
<>

<div className="ps-block__header">
            <p>{item.offerdetails[0].Offer.PromotionName} <span>{localStorage.getItem('ecurrencyCode')} -{item.OfferAmount.toFixed(2)}</span></p>
         </div>




</>
) : ''
))
}
</figure>
</div>
</>) : ''
}

</div>
        {/* Sub Total */}
        <div className="row">
          <div className="col-sm-12">
            <hr className="top-total-hr" />
            <div className="align-option">
              <div className="del-label mr20">Order Type</div>
              <div className="del-label">
                
              <div className="align-option">

              <Radio.Group onChange={props.onChange} value={props.params.value}>

{
   JSON.parse(sessionStorage.getItem('setting'))[0].SubOrderTypes.map(st => (
      <>

         {
            st.SubOrderTypeName == 'Click and Collect' && st.IsActive == true ? (
<>

<div className="form-check">
<Radio value={1} checked > Click & Collect</Radio>
                    <label className="form-check-label">FREE</label>
                  </div>

</>
                
            ) : ''
         }


         {
            st.SubOrderTypeName == 'Delivery' && st.IsActive == true ? (
               <>

<div className="form-check mr10">
<Radio value={0}>Delivery</Radio>
{
                           props.params.deliveryscheme.map(ds => {

                              //  console.log(ds)

                              if (ds.CurrencyCode == localStorage.getItem('ecurrencythreeCode')) {
                                 const Calculation = ds.Calculation;

                                 if (Calculation == "ByInvoiceAmount") {

                                    const vat = ds.ByInvoiceAmount;
                                    // console.log(ds.ByInvoiceAmount)
                                    // console.log(a)

                                    {
                                       [vat].map((e2, i2) => {



                                          if (props.params.total <= 50 && [vat][i2][50]) {
                                             sessionStorage.setItem('deliverycost', [vat][i2][50]);

                                          } else if (props.params.total <= 100 && [vat][i2][100]) {
                                             sessionStorage.setItem('deliverycost', [vat][i2][100]);

                                          } else if (props.params.total <= 150 && [vat][i2][150]) {
                                             sessionStorage.setItem('deliverycost', [vat][i2][150]);

                                          } else if (props.params.total <= 750 && [vat][i2][750]) {
                                             sessionStorage.setItem('deliverycost', [vat][i2][750]);

                                          } else {
                                             sessionStorage.setItem('deliverycost', [vat][i2]['Above']);

                                          }


                                       })
                                    }


                                    sessionStorage.setItem('MinimumInvoiceOrder', ds.MinimumInvoiceOrder);


                                 } else {
                                    //to do other methods
                                 }

                              } else {
                                 //to do other countries 
                              }
                           })
                        }


                        {localStorage.getItem('ecurrencyCode')}  {parseFloat(sessionStorage.getItem('deliverycost')).toFixed(2)}
                  </div>
                  
               </>
            ) : ''
         }


      </>
   )
   )
}




</Radio.Group>
              
                 
                 
                 
               
                



                </div>


              </div>
            </div>
            <div className="cart-sub-total">
              <div className="align-option">
                <div className="st-item st-label">Sub Total</div>
                <div className="st-item st-label">
                  <div className="align-option">
                    <span>{localStorage.getItem('ecurrencyCode')} {props.params.total}</span>
                  </div>
                </div>
              </div>


              {props.params.offer != 0 ? (<>
                            
               <div className="align-option">
                <div className="st-item st-label">Offer Discount</div>
                <div className="st-item st-label">
                  <div className="align-option">
                    <span>{localStorage.getItem('ecurrencyCode')} -{props.params.offer}</span>
                  </div>
                </div>
              </div>        
                            
                      
                           </>) : ''
                           }
              
              
              {props.params.value == 0 ? <>
                              
                          
              <div className="align-option">
                <div className="st-item st-label">Delivery Charge</div>
                <div className="st-item st-label">
                  <div className="align-option">
                    <span>{localStorage.getItem('ecurrencyCode')} {parseFloat(sessionStorage.getItem('deliverycost')).toFixed(2)}</span>
                  </div>
                </div>
              </div>
              </> : ''}

              <div className="align-option total-to-pay">
                <div className="st-item st-label">Total to Pay</div>
                <div className="st-item st-label">
                  <div className="align-option">
                    <span>{localStorage.getItem('ecurrencyCode')} {(parseFloat(props.params.total) - props.params.offer + (props.params.value == 0 ? parseFloat(sessionStorage.getItem('deliverycost')) : 0)).toFixed(2)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Sub Total */}
        {/* Action Buttons */}
        <div className="row">
          <div className="col-sm-12">
            <div className="cart-button-wrapper">
              <a onClick={props.onProceedCheckout.bind(this)} className="btn-act-primary">Checkout Now</a>
              <a href="/products" className="btn-act-secondary">Continue Shopping</a>
            </div>
          </div>
        </div>
        {/* /Action Buttons */}
      </div>
      {/* / Cart Details */}
   

      </>
   ) : (
         <>
            <Skeleton active avatar paragraph={{ rows: 4 }} />

         </>

      )

);
export default CartApparel;
