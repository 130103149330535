import React from "react";
import UnderConstructionLogo from "../assets/Images/under_construction.jpg";
export default function SiteUnderMaintain() {
  return (
    <div>
      <div
        style={{
          margin: "auto",
          marginTop: "200px",
          maxWidth: "480px",
          textAlign: "center",
          padding: "24px",
        }}
      >
        {/* <h1>
            Site Under Maintain
        </h1>
        <h3>
            Please try again in few minutes
        </h3>
         */}

        <img className='img-fluid' src={UnderConstructionLogo} />
      </div>

      <h2
        style={{
          textAlign: "center",
          fontFamily: "Times New Roman  Times, serif",
        }}
      >
        Oops ! We are working on some new updates for you, we know it’s taking a
        while but sit tight and we’ll be with you soon.
      </h2>
    </div>
  );
}
