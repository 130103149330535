import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import { Modal, Drawer, Select } from 'antd';
import config from 'react-global-configuration';
import Image from "./image.component";
import axios from 'axios';
import $ from 'jquery';
import jwt from 'jsonwebtoken';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

const { Option } = Select;

function getFaviconEl() {
  return document.getElementById("favicon");
}


export default class header extends Component {
  constructor(props) {
    super(props);
    this.state = { menusize: 0, clicked: '', brands: [], menus: [], pages: [], company: [], categories: [], basketitem: [], orgcurrency: [], defCCode: localStorage.getItem('ecurrencythreeCode') || 'GBP', defCSymbl: localStorage.getItem('ecurrencyCode') || '£' };
  }
  resize = () => {
    if (window.innerWidth > 1681) {
      this.setState({ menusize: 5 })
    } else if (window.innerWidth < 1400 && window.innerWidth > 1150) {
      this.setState({ menusize: 2 })
    } else {
      this.setState({ menusize: 3 })
    }
  }

  componentDidMount() {

    $('.site-overlay').click(function () {
      $("body").removeClass('mobile-nav-menu');
      $("body").removeClass('showSearch');
    });
    // Search on mobiles
    $('.btn-search').click(function () {
      $("body").addClass("showSearch");
    });



    
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.resize)
    this.resize();

    var hour = new Date().getHours();
    this.setState({ greet: "Hello" })

    var header = document.getElementById("navbar");

    const favicon = getFaviconEl(); // Accessing favicon element
    setTimeout(() => {
      if (config.get('Theme') == 'Apparel-casi') {
        require('./css/core_styles.css');
      }
    }, 100);


    favicon.href = 'https://cdn.neurolage.com/retailpacx/' + config.get('CDNPath').replace(/ /g, '').replace('&', '').replace(/(^\&)|,/g, '').toLowerCase() + '/' + config.get('favicon');

    axios.get(config.get('backendURL') + 'menus/main/' + config.get('companyID'))
      .then(response => {

        const requestOne = axios.get(config.get('backendURL') + 'pages/byid/' + response.data[0]._id + '/' + config.get('companyID'))
          .then(response => {
            this.setState({ pages: response.data })
          })
          .catch((error) => {
            console.log(error);
          })
      })
      .catch((error) => {
        console.log(error);
      })

    const cachedProduct = localStorage.getItem('cart');
    if (cachedProduct) {
      this.setState({ basketitem: JSON.parse(cachedProduct) });
    } else {
      const myArray = [];
      this.setState({ basketitem: myArray });
      localStorage.setItem('cart', JSON.stringify(myArray));
    }

    const cachedLogo = localStorage.getItem('cachedLogo');
    if (cachedLogo === '') {
      localStorage.setItem('cachedLogo', config.get('companyLogo'));
    }
    document.title = config.get('companyPageTitle')

    const timestamp = Math.floor(Date.now() / 1000) + 1000;

    var payload = {
      "aud": config.get('SalesjwtAud'),
      "exp": timestamp,
      "iss": config.get('SalesjwtIss')
    };

    var signOptions = {
      "algorithm": "RS256"
    };

    var privateKey = config.get('SalesprivateKey');
    var token = jwt.sign(payload, privateKey, signOptions);

    const cachedSetting = sessionStorage.getItem('setting');

    axios.get(config.get('API_salesregistry') + 'setting/' + config.get('OrganisationID') + '/26', { headers: { "Authorization": 'Bearer ' + token } })
      .then(response => {
        if (response.data.Result[0].IPLookup) {
          const cachedipresponse = localStorage.getItem('ipresponse');
          if (!cachedipresponse) {
            axios.get("https://api.ipstack.com/check?access_key=5e7152f63b2d02398b551ee66730bbfa")
              .then(ipresponse => {
                localStorage.setItem('ipresponse', JSON.stringify(ipresponse.data));
              })
          }
        }
        sessionStorage.setItem('setting', JSON.stringify(response.data.Result));
      })
      .catch((error) => {
        console.log(error);
      })

    axios.get(config.get('API_salesregistry') + 'currencies/' + config.get('OrganisationID') + '/26', { headers: { "Authorization": 'Bearer ' + token } })
      .then(response => {
        //console.log(response)
        this.setState({ orgcurrency: response.data.Result });
        this.state.orgcurrency.map(oc => {
          console.log(JSON.parse(localStorage.getItem('ipresponse')).currency.code);

          if (JSON.parse(localStorage.getItem('ipresponse')).currency.code == oc.CurrencyCode && !localStorage.getItem('dcurrency')) {
            localStorage.setItem('ecurrencyid', oc.CurrencyID);
            localStorage.setItem('ecurrencyratio', 1 * oc.CurrencyRate);
            localStorage.setItem('ecurrencyCode', oc.CurrencySymbol);
            localStorage.setItem('ecurrencythreeCode', oc.CurrencyCode);
            return true;

          } else if (oc.IsBaseCurrency == true && !localStorage.getItem('dcurrency')) {
            localStorage.setItem('ecurrencyid', oc.CurrencyID);
            localStorage.setItem('ecurrencyratio', 1 * oc.CurrencyRate);
            localStorage.setItem('ecurrencyCode', oc.CurrencySymbol);
            localStorage.setItem('ecurrencythreeCode', oc.CurrencyCode);
            return true;
          }

        })
      })
      .catch((error) => {
        console.log(error);
      })

    var payload2 = {
      "aud": config.get('ProductjwtAud'),
      "exp": timestamp,
      "iss": config.get('ProductjwtIss')
    };

    var privateKey2 = config.get('ProductprivateKey');
    var token2 = jwt.sign(payload2, privateKey2, signOptions);

    const requestCategories = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ` + token2,
      }
    };


    const cachedCategory = sessionStorage.getItem('menucategories');
    if (cachedCategory) {
      this.setState({ categories: JSON.parse(cachedCategory) });
    } else {
      fetch(config.get('rest_API_productregistry') + '/onlinecategories/' + config.get('OrganisationID'), requestCategories)
        .then(response => response.json())
        .then(data => this.onSetCategoryResult(data.Result, data.Result));
    }

    const requestBrands = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ` + token2,
      }
    };

    const cachedMenu = sessionStorage.getItem('menubrands');
    if (cachedMenu) {
      this.setState({ brands: JSON.parse(cachedMenu) });
    } else {
      fetch(config.get('rest_API_productregistry') + '/onlinebrands/' + config.get('OrganisationID'), requestBrands)
        .then(response => response.json())
        .then(data => this.onSetBrandResult(data.Result, data.Result));
    }


  }

  state = { visible: false, visible1: false, visible2: false, childrenDrawer: false, searchvisible: false };

  onSetBrandResult = (result, key) => {
    //  console.log(result)
    this.setState({ brands: result })
    sessionStorage.setItem('menubrands', JSON.stringify(result));
  };

  onSetCategoryResult = (result, key) => {

    this.setState({ categories: result })
    sessionStorage.setItem('menucategories', JSON.stringify(result));
  };
  componentWillUnmount() {

  }



  onCartClick = () => {
    const cachedProduct = localStorage.getItem('cart');
    if (cachedProduct) {
      this.setState({ basketitem: JSON.parse(cachedProduct) });
    } else {
      const myArray = [];
      this.setState({ basketitem: myArray });
      localStorage.setItem('cart', JSON.stringify(myArray));
    }

    this.setState({ clicked: 'clicked' })

  }

  onLogout = () => {

    sessionStorage.setItem('loggedin', false);
    sessionStorage.setItem('username', "");
    localStorage.setItem('fullUserDetails', "");
    sessionStorage.setItem('deliverycost', '');
    window.location.reload();
  };

  handlePageClick = (value) => {
    this.props.parentPageCallback(value)
  }
  handleCurrency = (value) => {
    //console.log(`selected ${value}`);

    localStorage.setItem('ecurrencyid', JSON.parse(value).CurrencyID);
    localStorage.setItem('ecurrencyratio', 1 * JSON.parse(value).CurrencyRate);
    localStorage.setItem('ecurrencyCode', JSON.parse(value).CurrencySymbol);
    localStorage.setItem('ecurrencythreeCode', JSON.parse(value).CurrencyCode);
    localStorage.setItem('dcurrency', "true");
    window.location.reload();

  }

  searchcNow = (event) => {

    event.preventDefault();

    if (document.getElementById("search").value) {

      window.location.href = "/search/" + document.getElementById("search").value
    } else {


      let secondsToGo = 5;
      const modal = Modal.warning({
        title: "Invalid or empty keyword",
        content: `Please enter a keyword to search`,
      });
      const timer = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
          content: `Please enter a keyword to search`,
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(timer);
        modal.destroy();
      }, secondsToGo * 1000);


    }

  };


  searchNow = (event) => {

    event.preventDefault();

    if (document.getElementById("searchnow").value) {
      window.location.href = "/search/" + document.getElementById("searchnow").value
    } else {

      let secondsToGo = 5;
      const modal = Modal.warning({
        title: "Invalid or empty keyword",
        content: `Please enter a keyword to search`,
      });
      const timer = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
          content: `Please enter a keyword to search`,
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(timer);
        modal.destroy();
      }, secondsToGo * 1000);

    }

  };

  showSearchDrawer = () => {
    this.setState({
      searchvisible: true,
    });
  };

  showDrawer = () => {
    const cachedCart = localStorage.getItem('cart');
    this.setState({
      visible: true,
      basketitem: JSON.parse(cachedCart)
    });
  };

  showDrawer1 = () => {
    this.setState({
      visible1: true
    });
  };
  showDrawer2 = () => {
    this.setState({
      visible2: true
    });
  };

  onSearchClose = () => {
    this.setState({
      searchvisible: false,
    });
  };
  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  onClose1 = () => {
    this.setState({
      visible1: false,
    });
  };
  onClose2 = () => {
    this.setState({
      visible2: false,
    });
  };

  clickButton = direction => () => {
    this.setState({ direction });
  };
  render() {
    this.state.offer = (this.state.basketitem.reduce((accumulator, currentofferValue) => accumulator + parseFloat(currentofferValue.OfferAmount * localStorage.getItem('ecurrencyratio')), 0)).toFixed(2)
    this.state.total = (this.state.basketitem.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.ProductQty * currentValue.ProductPrice * localStorage.getItem('ecurrencyratio')), 0)).toFixed(2)
    this.state.cartqty = (this.state.basketitem.reduce((accumulator, currentqtyValue) => accumulator + parseFloat(currentqtyValue.ProductQty), 0))
    const { basketitem, total, cartqty, categories, brands, offer } = this.state;
    const isBasketNotEmpty = this.state.basketitem.length;

    let button;

    return (
      <>

        <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
        <div className="site-overlay"></div>
        <div className="site-brand">
          <div className="inner-left">
            <div className="logo">
              <a href="/"> <Image params={{ ItemData: config.get('companyLogo'), className: 'img-fluid' }} /> </a>
            </div>

          </div>
          <div className="search-control">
            <Form onSubmit={this.searchcNow} className="ps-form--quick-search">
              <div className="input-group">

                <input className="form-control" type="text" id="search" placeholder="I'm shopping for..." />
                <div className="input-group-btn">
                  <button className="btn btn-default" onClick={this.searchcNow} type="submit">Find</button>
                </div>
              </div>
            </Form>
          </div>
          <div className="inner-right">
            <div className="header-right">
              <span className="btn-search"><i className="fas fa-search"></i></span>
              <div className="top-link-wrapper">
                <ul className="nav navbar-top-links">
                  <li className="dropdown">
                    <a className="dropdown-toggle currency-switch" data-toggle="dropdown" href="#" aria-expanded="false">
                      <div className="currency-text">
                        <span className="currency-name">{this.state.defCCode + " (" + this.state.defCSymbl + ")"}</span>
                      </div>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                      {
                        this.state.orgcurrency.map(ocd =>
                          <a className="dropdown-item" onClick={this.handleCurrency.bind(this, JSON.stringify(ocd))}><span>{ocd.CurrencyCode} ({ocd.CurrencySymbol})</span></a>
                        )}
                    </div>
                  </li>
                  <li className="dropdown">
                    <a className="dropdown-toggle drop-down-icon-hide" data-toggle="dropdown" href="#" aria-expanded="false">
                      <span className="favourite-items bg-round"><i className="far fa-heart"></i></span>
                      <span className="count">2</span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                      <div className="cart-top">
                        <div className="cart-top-item">
                          <div className="cart-top-product-img">
                            <img src="images/shopping-cart/product1.jpg" className="img-fluid" />
                          </div>
                          <div className="cart-top-product-wrap">
                            <div className="cart-top-prdocut-details">
                              <a href="#" className="cart-top-prdocut-name">Arraku Red Kanchipuram Saree Kanchipuram Saree</a>
                            </div>
                          </div>
                        </div>
                        <div className="cart-top-item">
                          <div className="cart-top-product-img">
                            <img src="images/shopping-cart/product1.jpg" className="img-fluid" />
                          </div>
                          <div className="cart-top-product-wrap">
                            <div className="cart-top-prdocut-details">
                              <a href="#" className="cart-top-prdocut-name">Arraku Red Kanchipuram Saree Kanchipuram Saree</a>
                            </div>
                          </div>
                        </div>
                        <div className="cart-top-buttons">
                          <a href="#" className="btn-act-primary"><span>Add To Cart</span></a>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="dropdown">
                    <a className="dropdown-toggle drop-down-icon-hide" data-toggle="dropdown" href="#" onMouseEnter={this.onCartClick.bind()} onClick={this.onCartClick.bind()} aria-expanded="false">
                      <span className="shopping-cart-items bg-round"><i className="fas fa-shopping-bag"></i></span>
                      <span id="cartcount" className="count">{
                        this.state.cartqty
                      }</span>

                    </a>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                      <div className="cart-top">


                        {this.state.basketitem.length ? (
                          this.state.basketitem.map(item => (
                            <>
                              <div className="cart-top-item" key={item.ProductID}>
                                <div className="cart-top-product-img">
                                  {
                                    item.ProductImage !== '' ?
                                      <img src={item.ProductImage} className="img-fluid" />
                                      : <img src="/images/noimage.jpg" alt="." className="img-fluid" />
                                  }
                                </div>
                                <div className="cart-top-product-wrap">
                                  <div className="cart-top-prdocut-details">
                                    <a href={'/product/' + item.ProductName.replace(/ /g, '-').toLowerCase() + '/' + item.ProductID} className="cart-top-prdocut-name">{item.ProductName} </a>
                                  </div>
                                  <div className="cart-top-prdocut-qty-price">
                                    <span>{item.ProductQty} x {localStorage.getItem('ecurrencyCode')} {(item.ProductPrice * localStorage.getItem('ecurrencyratio')).toFixed(2)}</span>
                                  </div>
                                </div>
                              </div>


                            </>

                          )
                          )
                        ) : (
                          <span>
                            <h6>Your cart is empty</h6>
                            <p>Find hundred of quality products at bargain prices everyday!</p>
                          </span>
                        )}


                        <div className="cart-top-total">
                          <div className="sub-total">
                            <span>Sub Total</span>
                            <span>{localStorage.getItem('ecurrencyCode')} {this.state.total}</span>
                          </div>



                          {
                            this.state.offer != 0 ? (<>

                              <div className="sub-total">
                                <span>Offer Discount</span>
                                <span>{localStorage.getItem('ecurrencyCode')} -{parseFloat(this.state.offer).toFixed(2)}</span>
                              </div>
                            </>
                            ) : ('')
                          }




                          <div className="total-topay">
                            <span>Total to Pay</span>
                            <span>{localStorage.getItem('ecurrencyCode')} {parseFloat(this.state.total - this.state.offer).toFixed(2)}</span>
                          </div>
                        </div>

                        <div className="cart-top-buttons">
                          <a href="/cart" className="btn-act-primary"><span>View Cart</span></a>
                        </div>

                      </div>
                    </div>
                  </li>
                  <li className="dropdown">
                    <a className="dropdown-toggle drop-down-icon-hide" data-toggle="dropdown" href={sessionStorage.getItem('loggedin') == "true" ? '/account/login' : '/account/login'} aria-expanded="false">
                      <span className="user-icon bg-round"><i className="far fa-user"></i></span>
                      <span className="profile-name">

                        {sessionStorage.getItem('loggedin') == "true" ? (
                          this.state.greet + ' ' + sessionStorage.getItem('username')

                        ) : (
                          <span>Sign In</span>

                        )}

                      </span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">


                      {sessionStorage.getItem('loggedin') == "true" ? (

                        <>
                          <a href="/account/member"><p className="loggedin-user">{sessionStorage.getItem('username')}</p></a>



                          <div className="hr-sep"><hr /></div>
                        </>

                      ) : (
                        <a href="/account/login">Login</a>

                      )}

                      {sessionStorage.getItem('loggedin') == "true" ? (
                        <a href="/account/login" onClick={this.onLogout.bind()}>Logout</a>
                      ) : (

                        <span> <a href="/account/register">Register</a></span>
                      )}



                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* /Branding Top */}



        <nav className="navbar navbar-expand-lg">
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-collapse">☰</button>
          <div className="collapse navbar-collapse" id="navbar-collapse">
            <ul className="nav navbar-nav ml-auto">


              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Shop by Category</a>
                <div className="dropdown-menu dropdown-menu-right">

                  {categories.map(category =>
                    <a className="dropdown-item" href={'/group/' + category.ProductCategory.ProductCategoryID + '/' + category.ProductCategory.Category}>{category.ProductCategory.Category}</a>
                  )}

                </div>
              </li>


              {
                this.state.pages.slice(0, this.state.menusize).map(currentpage =>
                  <li className="nav-item"><a className="nav-link" href="#" onClick={this.handlePageClick.bind(this, currentpage.slug)}>{currentpage.pagetitle}</a></li>

                )
              }


              {
                this.state.pages.length > 3 ? (
                  <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">More</a>

                    <div className="dropdown-menu dropdown-menu-right">
                      {
                        this.state.pages.slice(this.state.menusize - 1, 100).map(currentpage =>
                          <a className="dropdown-item" href="#" onClick={this.handlePageClick.bind(this, currentpage.slug)}>{currentpage.pagetitle}</a>
                        )
                      }

                    </div>

                  </li>
                ) : ('')
              }

            </ul>
          </div>
        </nav>

     

        <div>

          <Drawer
            title="Menu"
            placement="left"
            closable={true}
            width={440}
            getContainer={false}
            onClose={this.onClose1}
            visible={this.state.visible1}
            key="left"
          >

            <div class="" id="navigation-mobile">

              <div className="  ps-panel__content">
                <ul className="menu--mobile">
                  <li><a href="/">Home</a>
                  </li>
                  <li><a href="/products">Shop<ul className="menu--mobile">

                    {categories.slice(0, 15).map(category =>
                      <li><a href={'/group/' + category.ProductCategory.ProductCategoryID + '/' + category.ProductCategory.Category}> {category.ProductCategory.Category}</a> </li>

                    )}

                  </ul></a>
                  </li>
                  {
                    this.state.pages.map(currentpage =>
                      <li><a href={'/pages/' + currentpage.slug}>{currentpage.pagetitle}</a></li>
                    )
                  }
                </ul>
              </div>
            </div>

          </Drawer>
          <Drawer
            title="Search"
            placement="left"
            closable={true}
            width={440}
            getContainer={false}
            onClose={this.onSearchClose}
            visible={this.state.searchvisible}
            key="jleft"
          >

            <div className="ps-panel__header">
              <Form onSubmit={this.searchNow} className="ps-form--quick-search">
                <div className="form-group--icon"><i className="icon-chevron-down" />

                </div>
                <input className="form-control" type="text" id="searchnow" placeholder="I'm shopping for..." />
                <button onClick={this.searchNow}>Search</button>

              </Form>
            </div>
            <div className="navigation__content" />

          </Drawer>


          <Drawer
            title="Categories"
            placement="lleft"
            width={440}
            closable={true}
            getContainer={false}
            onClose={this.onClose2}
            visible={this.state.visible2}
            key="lleft"
          >
            <div className="ps-panel__content">
              <ul className="menu--mobile">

                {categories.slice(0, 15).map(category =>
                  <li><a href={'/group/' + category.ProductCategory.ProductCategoryID + '/' + category.ProductCategory.Category}> {category.ProductCategory.Category}</a> </li>

                )}

              </ul>
            </div>
          </Drawer>
        </div>

      </>

    );
  }
}