import React from 'react';
import { Row, Col, Breadcrumb } from 'react-bootstrap';
import { Modal, Select, Form, Button, Radio, Divider, Steps, Checkbox, Popover, Input } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import CheckoutMartFury from "../themes/martfury/checkout.jsx";
import CheckoutApparel from "../themes/apparel/checkout.jsx";
import config from 'react-global-configuration';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import jwt from 'jsonwebtoken';
import { FormInstance } from 'antd/lib/form';

const { Option } = Select;
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};


const { Step } = Steps;
const content = (
    <div>
        <p>By checking this box you are requesting to<br />
        sign for your delivery and should ensure <br />
        someone is home to receive it. Occasionally <br />
        our delivery partners may still choose to<br />
        require a signature even if this box is not<br /> selected.</p>
    </div>
);

class Application extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = { country: '', region: '', company: [], categories: [], basketitem: [], value: -1, deliveryvalue: false, deliverymethod: '' };
    }

    onChange = e => {
        //    console.log(e.target.value)
        sessionStorage.setItem('deliveryaddress', e.target.value);
        this.setState({
            value: e.target.value,
            deliverymethod: 'Delivery'
        });
    };
    onDeliveryTickChange = e => {
        this.setState({
            deliveryvalue: e.target.checked,
            deliverymethod: 'Click and Collect'
        });
    };



    selectAddress = (props, index) => {
        console.log(JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[index])

        this.formRef.current.setFieldsValue({
            Address: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[index].AddressLine1,
            Address2: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[index].AddressLine2,
            City: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[index].City,
            StateProvinceRegion: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[index].StateProvinceRegion,
            PostZipCode: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[index].PostZipCode,
            Country: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[index].Country,
            Email: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[index].Email,
            Phone: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[index].Phone,
            Fax: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[index].Fax,
        });
    };



    componentDidMount() {
        // console.log(JSON.parse(localStorage.getItem('fullUserDetails')).Addresses)
        sessionStorage.setItem('deliveryaddress', 0);
        const cachedProduct = localStorage.getItem('cart');
        if (cachedProduct) {
            this.setState({ basketitem: JSON.parse(cachedProduct) });
        } else {
            const myArray = [];
            this.setState({ basketitem: myArray });
            localStorage.setItem('cart', JSON.stringify(myArray));
        }
        document.title = "Checkout - Delivery Address Details | " + config.get('companyPageTitle')

        sessionStorage.setItem('deliveryaddress', this.state.value);

        sessionStorage.getItem('deliverytype') == 0 ? sessionStorage.setItem('deliverymethod', 'Delivery') : sessionStorage.setItem('deliverymethod', 'Click and Collect');
        sessionStorage.getItem('deliverytype') == 0 ? this.setState({
            deliverymethod: 'Delivery'
        }) : this.setState({
            deliverymethod: 'Click and Collect'
        });

    }
    selectCountry(val) {
        this.setState({ country: val });
    }

    selectRegion(val) {
        this.setState({ region: val });
    }

    showModal = () => {
        this.setState({
            visible: true
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false
        });

    };



    render() {
        const { value, visible } = this.state;
        this.state.offer = (this.state.basketitem.reduce((accumulator, currentofferValue) => accumulator + parseFloat(currentofferValue.OfferAmount * localStorage.getItem('ecurrencyratio')), 0)).toFixed(2)
        this.state.total = (this.state.basketitem.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.ProductQty * currentValue.ProductPrice * localStorage.getItem('ecurrencyratio')), 0)).toFixed(2)
        this.state.cartqty = (this.state.basketitem.reduce((accumulator, currentqtyValue) => accumulator + parseFloat(currentqtyValue.ProductQty), 0))
        const { basketitem, total, cartqty, offer } = this.state;
        const onFinish = values => {
            //  console.log(this.state.deliveryvalue);
            // DeliveryIns
            if (this.state.value == -1 && sessionStorage.getItem('deliverytype') != 1) {
                Modal.error({
                    title: 'Delivery address required.',
                    content: 'Please add or select a delivery address before proceed to payment.',
                    onOk() { },
                });
            } else {
                const delsig = this.state.deliveryvalue ? 'Delivery requires signature' : ''
                sessionStorage.setItem('DeliveryIns', delsig + " : " + values.DeliveryIns)
                //   console.log(sessionStorage.getItem('DeliveryIns'));
                window.location.href = "/payment"
            }
        };

        const onFinishFailed = errorInfo => {
            sessionStorage.setItem('DeliveryIns', errorInfo.DeliveryIns)
            window.location.href = "/payment"
        };

        const onCreateAddress = values => {

            this.setState({ disabled: true });
            this.refs.btn.setAttribute("disabled", "disabled");

            // console.log(values.AddressType);
            var fullUserDetails = JSON.parse(localStorage.getItem('fullUserDetails'));

            //   console.log(values.AddressType);
            const index = JSON.parse(localStorage.getItem('fullUserDetails')).Addresses.length;

            fullUserDetails["Addresses"].push({ "AddressType": values.AddressType, "AddressLine1": values.Address, "AddressLine2": values.Address2, "City": values.City, "StateProvinceRegion": values.StateProvinceRegion, "PostZipCode": values.PostZipCode, "Country": values.Country, "Phone": values.Phone, "Fax": values.Fax, "Email": values.Email })

            localStorage.setItem('fullUserDetails', JSON.stringify(fullUserDetails));

            const timestamp = Math.floor(Date.now() / 1000) + 1000;

            var payload = {
                "aud": config.get('CustjwtAud'),
                "exp": timestamp,
                "iss": config.get('CustjwtIss'),
                "usertoken": sessionStorage.getItem('AccessToken')
            };

            var signOptions = {
                "algorithm": "RS256"
            };

            var privateKey = config.get('LoginprivateKey');
            var token = jwt.sign(payload, privateKey, signOptions);
            //   console.log(token);

            const requestUserDtlsOptions = {
                method: 'PUT',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ` + token,
                },
                body: JSON.stringify(fullUserDetails)
            };
            //console.log(config.get('API_URL_cust') + 'update/' + config.get('OrganisationID') + '/' + fullUserDetails.UserID)
            //console.log(fullUserDetails)
            fetch(config.get('API_URL_cust') + 'update/' + config.get('OrganisationID') + '/' + fullUserDetails.UserID, requestUserDtlsOptions)
                .then(response => response.json())
                .then(function (data) {
                    //  console.log(data.Result);
                    Modal.success({
                        title: 'Address Details',
                        content: 'Address has been added, Please choose a address',
                        onOk() { window.location.href = "/checkout" },
                    });
                });

        };

        //  console.log(sessionStorage.getItem('deliveryaddress'))
        const { country, region } = this.state;
        return (
            <>
                {(() => {

                    switch (config.get('Theme')) {
                        case 'Apparel':
                            return (

                                <div style={{ backgroundColor: "#f5f5f5" }}>
                                    {
                                        sessionStorage.getItem('deliverytype') == 1 ? sessionStorage.setItem('deliverycost', 0) : ''
                                    }
                                    <Form
                                        {...layout}
                                        name="basic"
                                        initialValues={{ remember: true }}
                                        onFinish={onFinish}
                                        onFinishFailed={onFinishFailed}
                                    >
                                        <Row>

                                            <Col sm={12} >
                                                <Breadcrumb>
                                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                                    <Breadcrumb.Item href="checkout/">
                                                        Checkout
                            </Breadcrumb.Item>
                                                </Breadcrumb>
                                            </Col>
                                            <Col sm={1} >
                                            </Col>
                                            <Col sm={9}>
                                                <div className="fade alert alert-light show">
                                                    <Steps current={0}>
                                                        <Step title={(this.state.deliverymethod).toUpperCase()} description="Delivery/Pickup." />
                                                        <Step title="PAYMENT / PLACE ORDER" description="Payment details." />
                                                        <Step title="THANK YOU" description="For choosing us." />
                                                    </Steps>
                                                </div>
                                            </Col>
                                            <Col sm={2} >
                                            </Col>

                                            <Col sm={1}>
                                            </Col>
                                            <Col sm={7}>

                                                {
                                                    sessionStorage.getItem('deliverytype') == 1 ? (

                                                        <div className="fade alert alert-light show">


                                                            <Divider orientation="left"><h5>CLICK AND COLLECT</h5></Divider>


                                                            <Row>
                                                                <Col sm={12}>

                                                                    The person collecting your order will need to bring a copy of the order confirmation and suitable identification such as a photo ID. Once payment is approved or unless paying by cash, your order will be ready for collection in store within our store opening time. We will contact you by email or phone when your full order is ready to be picked up.

    </Col>

                                                                <Col sm={12}>&nbsp;</Col>
                                                                <Col sm={4}>
                                                                </Col>
                                                                <Col sm={4}>

                                                                    <Button type="primary" htmlType="submit" danger >

                                                                        Proceed to Payment
    </Button>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    ) : (

                                                            <div className="fade alert alert-light show">


                                                                <Divider orientation="left"><h5>SELECT OR CREATE A DELIVERY ADDRESS</h5></Divider>


                                                                <Row>
                                                                    <Col sm={12}>


                                                                    </Col>
                                                                    <Col sm={8}>

                                                                        <b>USE A SAVED ADDRESS</b>


                                                                    </Col>
                                                                    <Col sm={4}>

                                                                        <Button color="primary" onClick={this.showModal.bind(this, 100)} style={{ marginBottom: '1rem' }}>
                                                                            Create a new address
                </Button>



                                                                    </Col>

                                                                    <Col sm={12}>
                                                                        <Radio.Group onChange={this.onChange} value={this.state.value}>

                                                                            {

                                                                                JSON.parse(localStorage.getItem('fullUserDetails')).Addresses.map((address, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            {address.AddressType == "Delivery" ? (

                                                                                                <>
                                                                                                    <Radio value={index}>
                                                                                                        {address.AddressType ? (<><b> {address.AddressType} </b><br /></>) : ''}
                                                                                                        {address.AddressLine1 ? (<>{address.AddressLine1}<br /></>) : ''}
                                                                                                        {address.AddressLine2 ? (<>{address.AddressLine2}<br /></>) : ''}
                                                                                                        {address.City ? (<>{address.City}<br /></>) : ''}
                                                                                                        {address.StateProvinceRegion ? (<>{address.StateProvinceRegion}&nbsp;</>) : ''}
                                                                                                        {address.PostZipCode ? (<>{address.PostZipCode}<br /></>) : ''}
                                                                                                        {address.Country ? (<>{address.Country}<br /> </>) : ''}
                                                                                                        {address.Email ? (<><b>Email: </b>{address.Email}<br /></>) : ''}
                                                                                                        {address.Phone ? (<><b>Phone: </b>{address.Phone}<br /></>) : ''}
                                                                                                        {address.Fax ? (<><b>Fax: </b>{address.Fax}<br /></>) : ''}
                                                                                                    </Radio>
                                                                                                    <hr />
                                                                                                </>
                                                                                            ) : ''

                                                                                            }
                                                                                        </>
                                                                                    );
                                                                                })
                                                                            }
                                                                        </Radio.Group>


                                                                    </Col>
                                                                    <Col sm={12}>
                                                                        <Divider orientation="left"><h5>DELIVERY INSTRUCTIONS</h5></Divider>
                                                                        <Form.Item
                                                                            name="signature"
                                                                            label=""
                                                                        >
                                                                            <Checkbox onChange={this.onDeliveryTickChange} >Delivery requires signature   <Popover content={content} className="homeMessage" title="Delivery requires signature">
                                                                                <u>What is this?</u>
                                                                            </Popover></Checkbox>
                                                                        </Form.Item>



                                                                        <Form.Item
                                                                            name="DeliveryIns"
                                                                            label="Delivery Instructions"
                                                                        >
                                                                            <Input placeholder="e.g. Knock at the back door" />
                                                                        </Form.Item>

                                                                    </Col>
                                                                    <Col sm={4}>
                                                                    </Col>
                                                                    <Col sm={4}>

                                                                        <Button type="primary" htmlType="submit" danger >

                                                                            Proceed to Payment
            </Button>
                                                                    </Col>




                                                                </Row>

                                                            </div>
                                                        )
                                                }


                                            </Col>
                                            <Col sm={4}>

                                                {this.state.basketitem.length ? (
                                                    <div className="fade alert alert-light show">
                                                        <Row className="justify-content-md-center">
                                                            <Col sm={12}>
                                                                <h5>ORDER SUMMARY <a href="/cart" className="float-right"><EditOutlined /></a></h5>
                                                            </Col>
                                                            <Col sm={12}><hr /></Col>
                                                            <Col sm={12}>
                                                                <div className="fade alert alert-light show">
                                                                    {this.state.basketitem.length ? (
                                                                        this.state.basketitem.map(item => (
                                                                            <Row>

                                                                                <Col xs={12} md={3}>{
                                                                                    item.ProductImage !== '' ?
                                                                                        <img src={item.ProductImage} width="80" />
                                                                                        : <img src="/images/noimage.jpg" alt="." width="80" />
                                                                                }</Col>
                                                                                <Col xs={12} md={9}><b>{item.ProductName}</b>
                                                                                    <Row>
                                                                                        <Col xs={8} md={6}>Qty: {item.ProductQty}</Col>
                                                                                        <Col xs={4} md={6} className="text-right"><b>{localStorage.getItem('ecurrencyCode')} {(item.ProductQty * item.ProductPrice * localStorage.getItem('ecurrencyratio')).toFixed(2)}</b></Col>
                                                                                    </Row>
                                                                                </Col>

                                                                                <Col md={12}><hr></hr></Col>


                                                                            </Row>
                                                                        )
                                                                        )
                                                                    ) : (
                                                                            <span>
                                                                                <h6>Your cart is empty</h6>
                                                                                <p>Find hundred of quality products at bargain prices everyday!</p>
                                                                            </span>
                                                                        )}

                                                                </div>

                                                            </Col>
                                                            <Col sm={12}><hr /></Col>
                                                            <Col sm={12}>
                                                                <h5>Price Details</h5>
                                                            </Col>
                                                            <Col sm={9} xs={8}>

                                                                Subtotal:
                </Col>
                                                            <Col sm={3} xs={4}>
                                                                <b>{localStorage.getItem('ecurrencyCode')} {this.state.total}</b>
                                                            </Col>


                                                            {
                                                                sessionStorage.getItem('deliverytype') == 1 ? (
                                                                    <>
                                                                        <Col sm={9} xs={8}>

                                                                            Click and Collect:
                                   </Col>
                                                                        <Col sm={3} xs={4}>
                                                                            <b>FREE</b>
                                                                        </Col>
                                                                    </>
                                                                ) : (
                                                                        <>
                                                                            <Col sm={9} xs={8}>

                                                                                Delivery:
                                 </Col>
                                                                            <Col sm={3} xs={4}>
                                                                                {localStorage.getItem('ecurrencyCode')} {parseFloat(sessionStorage.getItem('deliverycost')).toFixed(2)}
                                                                            </Col>
                                                                        </>
                                                                    )
                                                            }

                                                            {this.state.offer != 0 ? (<>
                                                                <Col sm={9} xs={8}>

                                                                    <p class="text-danger">Offer Discount:</p>
                                                                </Col>
                                                                <Col sm={3} xs={4}>
                                                                    <p class="text-danger">
                                                                        {localStorage.getItem('ecurrencyCode')} -{this.state.offer}</p>

                                                                </Col></>) : ''
                                                            }


                                                            <Col sm={12}><hr /></Col>
                                                            <Col sm={9} xs={8}>

                                                                <b>TOTAL:</b>
                                                            </Col>
                                                            <Col sm={3} xs={4}>
                                                                <b>{localStorage.getItem('ecurrencyCode')} {(parseFloat(this.state.total) - this.state.offer + parseFloat(sessionStorage.getItem('deliverycost'))).toFixed(2)}</b>
                                                            </Col>
                                                            <Col sm={12}>&nbsp;</Col>
                                                            <Col sm={2}>

                                                            </Col>


                                                        </Row>

                                                    </div>

                                                ) : (
                                                        <span>

                                                        </span>
                                                    )}
                                            </Col>


                                        </Row>

                                    </Form>

                                    <Modal
                                        visible={visible}
                                        title="NEW DELIVERY ADDRESS INFORMATION"
                                        width="1000px"
                                        onCancel={this.handleCancel}
                                        footer={[
                                            <Button key="back" onClick={this.handleCancel}>
                                                Return
                    </Button>,

                                        ]}
                                    >

                                        <>

                                            <Form layout="vertical"
                                                name="register"
                                                ref={this.formRef}
                                                onFinish={onCreateAddress} >

                                                <Form.Item
                                                    name="SelectAddress"
                                                    extra="Please click below add new button to save the address"
                                                    label="Select an existing address to add"
                                                >

                                                    <Select
                                                        onChange={(val) => this.selectAddress(this, val)}
                                                    >
                                                        {

                                                            JSON.parse(localStorage.getItem('fullUserDetails')).Addresses.map((address, index) => {
                                                                return (
                                                                    <>

                                                                        {
                                                                            <>

                                                                                {address.AddressType == "Billing" ? (

                                                                                    <Option value={index}>
                                                                                        {address.AddressLine1 ? (<>{address.AddressLine1}<br /></>) : ''} {address.AddressLine2 ? (<>{address.AddressLine2}<br /></>) : ''} {address.City ? (<>{address.City}<br /></>) : ''}
                                                                                        {address.StateProvinceRegion ? (<>{address.StateProvinceRegion}&nbsp;</>) : ''}
                                                                                        {address.PostZipCode ? (<>{address.PostZipCode}<br /></>) : ''}
                                                                                        {address.Country ? (<>{address.Country}<br /> </>) : ''}
                                                                                        {address.Email ? (<><b>Email: </b>{address.Email}<br /></>) : ''}
                                                                                        {address.Phone ? (<><b>Phone: </b>{address.Phone}<br /></>) : ''}
                                                                                        {address.Fax ? (<><b>Fax: </b>{address.Fax}<br /></>) : ''}
                                                                                    </Option>

                                                                                ) : ''
                                                                                }
                                                                            </>


                                                                        }
                                                                    </>
                                                                );
                                                            })
                                                        }


                                                    </Select>

                                                </Form.Item>


                                                <Form.Item
                                                    name="AddressType"
                                                    label="Address Type"
                                                    initialValue="Delivery"
                                                    rules={[{ required: true, message: 'Please enter a address type' }]}
                                                >

                                                    <Select   >

                                                        <Option value="Delivery">Delivery</Option>

                                                    </Select>

                                                </Form.Item>


                                                <Form.Item
                                                    name="Address"
                                                    label="Address"
                                                    rules={[{ required: true, message: 'Please enter address' }]}
                                                >
                                                    <Input id="Address" placeholder="Please enter address" />
                                                </Form.Item>

                                                <Form.Item
                                                    name="Address2"
                                                    label="Address 2"
                                                    rules={[{ required: false, message: 'Please enter address 2' }]}
                                                >
                                                    <Input placeholder="Please enter address 2" />
                                                </Form.Item>

                                                <Form.Item
                                                    name="City"
                                                    label="City"
                                                    rules={[{ required: true, message: 'Please enter city' }]}
                                                >
                                                    <Input placeholder="Please enter city" />
                                                </Form.Item>

                                                <Form.Item
                                                    name="StateProvinceRegion"
                                                    label="State / Province / Region"
                                                    rules={[{ required: true, message: 'Please enter state / province / region' }]}
                                                >
                                                    <RegionDropdown
                                                        country={country}
                                                        value={region}
                                                        countryValueType="short"
                                                        classes="ant-input"
                                                        onChange={(val) => this.selectRegion(val)} />
                                                </Form.Item>

                                                <Form.Item
                                                    name="PostZipCode"
                                                    label="Post / Zip Code"
                                                    rules={[{ required: true, message: 'Please enter post / zip code' }]}
                                                >
                                                    <Input placeholder="Please enter post / zip code" />
                                                </Form.Item>

                                                <Form.Item
                                                    name="Country"
                                                    label="Country"
                                                    rules={[{ required: true, message: 'Please enter country' }]}
                                                >
                                                    <CountryDropdown
                                                        value={country}
                                                        valueType="short"
                                                        classes="ant-input"
                                                        onChange={(val) => this.selectCountry(val)} />
                                                </Form.Item>

                                                <Form.Item
                                                    name="Phone"
                                                    label="Phone"
                                                    rules={[{ required: true, message: 'Please enter valid phone' }]}
                                                >
                                                    <Input placeholder="Please enter phone" />
                                                </Form.Item>

                                                <Form.Item
                                                    name="Fax"
                                                    label="Fax"
                                                    rules={[{ message: 'Please enter valid fax' }]}
                                                >
                                                    <Input placeholder="Please enter fax" />
                                                </Form.Item>

                                                <Form.Item
                                                    name="Email"
                                                    label="Email"
                                                    rules={[{ type: 'email', required: true, message: 'Please enter valid email' }]}
                                                >
                                                    <Input placeholder="Please enter email" />
                                                </Form.Item>

                                                <Form.Item>
                                                    <Button type="primary" htmlType="submit" ref="btn" danger >
                                                        {this.state.disabled ? 'Please wait...' : 'Add New Address'}
                                                    </Button>
                                                </Form.Item>
                                            </Form>

                                        </>



                                    </Modal>
                                </div>
                            )
                        case 'Martfury':
                            return (

                                <div style={{ backgroundColor: "#f5f5f5" }}>
                                    {
                                        sessionStorage.getItem('deliverytype') == 1 ? sessionStorage.setItem('deliverycost', 0) : ''
                                    }
                                    <Form
                                        {...layout}
                                        name="basic"
                                        initialValues={{ remember: true }}
                                        onFinish={onFinish}
                                        onFinishFailed={onFinishFailed}
                                    >
                                        <Row>

                                            <Col sm={12} >
                                                <Breadcrumb>
                                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                                    <Breadcrumb.Item href="checkout/">
                                                        Checkout
                            </Breadcrumb.Item>
                                                </Breadcrumb>
                                            </Col>
                                            <Col sm={1} >
                                            </Col>
                                            <Col sm={9}>
                                                <div className="fade alert alert-light show">
                                                    <Steps current={0}>
                                                        <Step title={(this.state.deliverymethod).toUpperCase()} description="Delivery/Pickup." />
                                                        <Step title="PAYMENT / PLACE ORDER" description="Payment details." />
                                                        <Step title="THANK YOU" description="For choosing us." />
                                                    </Steps>
                                                </div>
                                            </Col>
                                            <Col sm={2} >
                                            </Col>

                                            <Col sm={1}>
                                            </Col>
                                            <Col sm={7}>

                                                {
                                                    sessionStorage.getItem('deliverytype') == 1 ? (

                                                        <div className="fade alert alert-light show">


                                                            <Divider orientation="left"><h5>CLICK AND COLLECT</h5></Divider>


                                                            <Row>
                                                                <Col sm={12}>

                                                                    The person collecting your order will need to bring a copy of the order confirmation and suitable identification such as a photo ID. Once payment is approved or unless paying by cash, your order will be ready for collection in store within our store opening time. We will contact you by email or phone when your full order is ready to be picked up.

    </Col>

                                                                <Col sm={12}>&nbsp;</Col>
                                                                <Col sm={4}>
                                                                </Col>
                                                                <Col sm={4}>

                                                                    <Button type="primary" htmlType="submit" danger >

                                                                        Proceed to Payment
    </Button>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    ) : (

                                                            <div className="fade alert alert-light show">


                                                                <Divider orientation="left"><h5>SELECT OR CREATE A DELIVERY ADDRESS</h5></Divider>


                                                                <Row>
                                                                    <Col sm={12}>


                                                                    </Col>
                                                                    <Col sm={8}>

                                                                        <b>USE A SAVED ADDRESS</b>


                                                                    </Col>
                                                                    <Col sm={4}>

                                                                        <Button color="primary" onClick={this.showModal.bind(this, 100)} style={{ marginBottom: '1rem' }}>
                                                                            Create a new address
                </Button>



                                                                    </Col>

                                                                    <Col sm={12}>
                                                                        <Radio.Group onChange={this.onChange} value={this.state.value}>

                                                                            {

                                                                                JSON.parse(localStorage.getItem('fullUserDetails')).Addresses.map((address, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            {address.AddressType == "Delivery" ? (

                                                                                                <>
                                                                                                    <Radio value={index}>
                                                                                                        {address.AddressType ? (<><b> {address.AddressType} </b><br /></>) : ''}
                                                                                                        {address.AddressLine1 ? (<>{address.AddressLine1}<br /></>) : ''}
                                                                                                        {address.AddressLine2 ? (<>{address.AddressLine2}<br /></>) : ''}
                                                                                                        {address.City ? (<>{address.City}<br /></>) : ''}
                                                                                                        {address.StateProvinceRegion ? (<>{address.StateProvinceRegion}&nbsp;</>) : ''}
                                                                                                        {address.PostZipCode ? (<>{address.PostZipCode}<br /></>) : ''}
                                                                                                        {address.Country ? (<>{address.Country}<br /> </>) : ''}
                                                                                                        {address.Email ? (<><b>Email: </b>{address.Email}<br /></>) : ''}
                                                                                                        {address.Phone ? (<><b>Phone: </b>{address.Phone}<br /></>) : ''}
                                                                                                        {address.Fax ? (<><b>Fax: </b>{address.Fax}<br /></>) : ''}
                                                                                                    </Radio>
                                                                                                    <hr />
                                                                                                </>
                                                                                            ) : ''

                                                                                            }
                                                                                        </>
                                                                                    );
                                                                                })
                                                                            }
                                                                        </Radio.Group>


                                                                    </Col>
                                                                    <Col sm={12}>
                                                                        <Divider orientation="left"><h5>DELIVERY INSTRUCTIONS</h5></Divider>
                                                                        <Form.Item
                                                                            name="signature"
                                                                            label=""
                                                                        >
                                                                            <Checkbox onChange={this.onDeliveryTickChange} >Delivery requires signature   <Popover content={content} className="homeMessage" title="Delivery requires signature">
                                                                                <u>What is this?</u>
                                                                            </Popover></Checkbox>
                                                                        </Form.Item>



                                                                        <Form.Item
                                                                            name="DeliveryIns"
                                                                            label="Delivery Instructions"
                                                                        >
                                                                            <Input placeholder="e.g. Knock at the back door" />
                                                                        </Form.Item>

                                                                    </Col>
                                                                    <Col sm={4}>
                                                                    </Col>
                                                                    <Col sm={4}>

                                                                        <Button type="primary" htmlType="submit" danger >

                                                                            Proceed to Payment
            </Button>
                                                                    </Col>




                                                                </Row>

                                                            </div>
                                                        )
                                                }


                                            </Col>
                                            <Col sm={4}>

                                                {this.state.basketitem.length ? (
                                                    <div className="fade alert alert-light show">
                                                        <Row className="justify-content-md-center">
                                                            <Col sm={12}>
                                                                <h5>ORDER SUMMARY <a href="/cart" className="float-right"><EditOutlined /></a></h5>
                                                            </Col>
                                                            <Col sm={12}><hr /></Col>
                                                            <Col sm={12}>
                                                                <div className="fade alert alert-light show">
                                                                    {this.state.basketitem.length ? (
                                                                        this.state.basketitem.map(item => (
                                                                            <Row>

                                                                                <Col xs={12} md={3}>{
                                                                                    item.ProductImage !== '' ?
                                                                                        <img src={item.ProductImage} width="80" />
                                                                                        : <img src="/images/noimage.jpg" alt="." width="80" />
                                                                                }</Col>
                                                                                <Col xs={12} md={9}><b>{item.ProductName}</b>
                                                                                    <Row>
                                                                                        <Col xs={8} md={6}>Qty: {item.ProductQty}</Col>
                                                                                        <Col xs={4} md={6} className="text-right"><b>{localStorage.getItem('ecurrencyCode')} {(item.ProductQty * item.ProductPrice * localStorage.getItem('ecurrencyratio')).toFixed(2)}</b></Col>
                                                                                    </Row>
                                                                                </Col>

                                                                                <Col md={12}><hr></hr></Col>


                                                                            </Row>
                                                                        )
                                                                        )
                                                                    ) : (
                                                                            <span>
                                                                                <h6>Your cart is empty</h6>
                                                                                <p>Find hundred of quality products at bargain prices everyday!</p>
                                                                            </span>
                                                                        )}

                                                                </div>

                                                            </Col>
                                                            <Col sm={12}><hr /></Col>
                                                            <Col sm={12}>
                                                                <h5>Price Details</h5>
                                                            </Col>
                                                            <Col sm={9} xs={8}>

                                                                Subtotal:
                </Col>
                                                            <Col sm={3} xs={4}>
                                                                <b>{localStorage.getItem('ecurrencyCode')} {this.state.total}</b>
                                                            </Col>


                                                            {
                                                                sessionStorage.getItem('deliverytype') == 1 ? (
                                                                    <>
                                                                        <Col sm={9} xs={8}>

                                                                            Click and Collect:
                                   </Col>
                                                                        <Col sm={3} xs={4}>
                                                                            <b>FREE</b>
                                                                        </Col>
                                                                    </>
                                                                ) : (
                                                                        <>
                                                                            <Col sm={9} xs={8}>

                                                                                Delivery:
                                 </Col>
                                                                            <Col sm={3} xs={4}>
                                                                                {localStorage.getItem('ecurrencyCode')} {parseFloat(sessionStorage.getItem('deliverycost')).toFixed(2)}
                                                                            </Col>
                                                                        </>
                                                                    )
                                                            }

                                                            {this.state.offer != 0 ? (<>
                                                                <Col sm={9} xs={8}>

                                                                    <p class="text-danger">Offer Discount:</p>
                                                                </Col>
                                                                <Col sm={3} xs={4}>
                                                                    <p class="text-danger">
                                                                        {localStorage.getItem('ecurrencyCode')} -{this.state.offer}</p>

                                                                </Col></>) : ''
                                                            }


                                                            <Col sm={12}><hr /></Col>
                                                            <Col sm={9} xs={8}>

                                                                <b>TOTAL:</b>
                                                            </Col>
                                                            <Col sm={3} xs={4}>
                                                                <b>{localStorage.getItem('ecurrencyCode')} {(parseFloat(this.state.total) - this.state.offer + parseFloat(sessionStorage.getItem('deliverycost'))).toFixed(2)}</b>
                                                            </Col>
                                                            <Col sm={12}>&nbsp;</Col>
                                                            <Col sm={2}>

                                                            </Col>


                                                        </Row>

                                                    </div>

                                                ) : (
                                                        <span>

                                                        </span>
                                                    )}
                                            </Col>


                                        </Row>

                                    </Form>

                                    <Modal
                                        visible={visible}
                                        title="NEW DELIVERY ADDRESS INFORMATION"
                                        width="1000px"
                                        onCancel={this.handleCancel}
                                        footer={[
                                            <Button key="back" onClick={this.handleCancel}>
                                                Return
                    </Button>,

                                        ]}
                                    >

                                        <>

                                            <Form layout="vertical"
                                                name="register"
                                                ref={this.formRef}
                                                onFinish={onCreateAddress} >

                                                <Form.Item
                                                    name="SelectAddress"
                                                    extra="Please click below add new button to save the address"
                                                    label="Select an existing address to add"
                                                >

                                                    <Select
                                                        onChange={(val) => this.selectAddress(this, val)}
                                                    >
                                                        {

                                                            JSON.parse(localStorage.getItem('fullUserDetails')).Addresses.map((address, index) => {
                                                                return (
                                                                    <>

                                                                        {
                                                                            <>

                                                                                {address.AddressType == "Billing" ? (

                                                                                    <Option value={index}>
                                                                                        {address.AddressLine1 ? (<>{address.AddressLine1}<br /></>) : ''} {address.AddressLine2 ? (<>{address.AddressLine2}<br /></>) : ''} {address.City ? (<>{address.City}<br /></>) : ''}
                                                                                        {address.StateProvinceRegion ? (<>{address.StateProvinceRegion}&nbsp;</>) : ''}
                                                                                        {address.PostZipCode ? (<>{address.PostZipCode}<br /></>) : ''}
                                                                                        {address.Country ? (<>{address.Country}<br /> </>) : ''}
                                                                                        {address.Email ? (<><b>Email: </b>{address.Email}<br /></>) : ''}
                                                                                        {address.Phone ? (<><b>Phone: </b>{address.Phone}<br /></>) : ''}
                                                                                        {address.Fax ? (<><b>Fax: </b>{address.Fax}<br /></>) : ''}
                                                                                    </Option>

                                                                                ) : ''
                                                                                }
                                                                            </>


                                                                        }
                                                                    </>
                                                                );
                                                            })
                                                        }


                                                    </Select>

                                                </Form.Item>


                                                <Form.Item
                                                    name="AddressType"
                                                    label="Address Type"
                                                    initialValue="Delivery"
                                                    rules={[{ required: true, message: 'Please enter a address type' }]}
                                                >

                                                    <Select   >

                                                        <Option value="Delivery">Delivery</Option>

                                                    </Select>

                                                </Form.Item>


                                                <Form.Item
                                                    name="Address"
                                                    label="Address"
                                                    rules={[{ required: true, message: 'Please enter address' }]}
                                                >
                                                    <Input id="Address" placeholder="Please enter address" />
                                                </Form.Item>

                                                <Form.Item
                                                    name="Address2"
                                                    label="Address 2"
                                                    rules={[{ required: false, message: 'Please enter address 2' }]}
                                                >
                                                    <Input placeholder="Please enter address 2" />
                                                </Form.Item>

                                                <Form.Item
                                                    name="City"
                                                    label="City"
                                                    rules={[{ required: true, message: 'Please enter city' }]}
                                                >
                                                    <Input placeholder="Please enter city" />
                                                </Form.Item>

                                                <Form.Item
                                                    name="StateProvinceRegion"
                                                    label="State / Province / Region"
                                                    rules={[{ required: true, message: 'Please enter state / province / region' }]}
                                                >
                                                    <RegionDropdown
                                                        country={country}
                                                        value={region}
                                                        countryValueType="short"
                                                        classes="ant-input"
                                                        onChange={(val) => this.selectRegion(val)} />
                                                </Form.Item>

                                                <Form.Item
                                                    name="PostZipCode"
                                                    label="Post / Zip Code"
                                                    rules={[{ required: true, message: 'Please enter post / zip code' }]}
                                                >
                                                    <Input placeholder="Please enter post / zip code" />
                                                </Form.Item>

                                                <Form.Item
                                                    name="Country"
                                                    label="Country"
                                                    rules={[{ required: true, message: 'Please enter country' }]}
                                                >
                                                    <CountryDropdown
                                                        value={country}
                                                        valueType="short"
                                                        classes="ant-input"
                                                        onChange={(val) => this.selectCountry(val)} />
                                                </Form.Item>

                                                <Form.Item
                                                    name="Phone"
                                                    label="Phone"
                                                    rules={[{ required: true, message: 'Please enter valid phone' }]}
                                                >
                                                    <Input placeholder="Please enter phone" />
                                                </Form.Item>

                                                <Form.Item
                                                    name="Fax"
                                                    label="Fax"
                                                    rules={[{ message: 'Please enter valid fax' }]}
                                                >
                                                    <Input placeholder="Please enter fax" />
                                                </Form.Item>

                                                <Form.Item
                                                    name="Email"
                                                    label="Email"
                                                    rules={[{ type: 'email', required: true, message: 'Please enter valid email' }]}
                                                >
                                                    <Input placeholder="Please enter email" />
                                                </Form.Item>

                                                <Form.Item>
                                                    <Button type="primary" htmlType="submit" ref="btn" danger >
                                                        {this.state.disabled ? 'Please wait...' : 'Add New Address'}
                                                    </Button>
                                                </Form.Item>
                                            </Form>

                                        </>



                                    </Modal>
                                </div>
                            )
                        default:
                            return (

                                <div style={{ backgroundColor: "#f5f5f5" }}>
                                    {
                                        sessionStorage.getItem('deliverytype') == 1 ? sessionStorage.setItem('deliverycost', 0) : ''
                                    }
                                    <Form
                                        {...layout}
                                        name="basic"
                                        initialValues={{ remember: true }}
                                        onFinish={onFinish}
                                        onFinishFailed={onFinishFailed}
                                    >
                                        <Row>

                                            <Col sm={12} >
                                                <Breadcrumb>
                                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                                    <Breadcrumb.Item href="checkout/">
                                                        Checkout
                            </Breadcrumb.Item>
                                                </Breadcrumb>
                                            </Col>
                                            <Col sm={1} >
                                            </Col>
                                            <Col sm={9}>
                                                <div className="fade alert alert-light show">
                                                    <Steps current={0}>
                                                        <Step title={(this.state.deliverymethod).toUpperCase()} description="Delivery/Pickup." />
                                                        <Step title="PAYMENT / PLACE ORDER" description="Payment details." />
                                                        <Step title="THANK YOU" description="For choosing us." />
                                                    </Steps>
                                                </div>
                                            </Col>
                                            <Col sm={2} >
                                            </Col>

                                            <Col sm={1}>
                                            </Col>
                                            <Col sm={7}>

                                                {
                                                    sessionStorage.getItem('deliverytype') == 1 ? (

                                                        <div className="fade alert alert-light show">


                                                            <Divider orientation="left"><h5>CLICK AND COLLECT</h5></Divider>


                                                            <Row>
                                                                <Col sm={12}>

                                                                    The person collecting your order will need to bring a copy of the order confirmation and suitable identification such as a photo ID. Once payment is approved or unless paying by cash, your order will be ready for collection in store within our store opening time. We will contact you by email or phone when your full order is ready to be picked up.

    </Col>

                                                                <Col sm={12}>&nbsp;</Col>
                                                                <Col sm={4}>
                                                                </Col>
                                                                <Col sm={4}>

                                                                    <Button type="primary" htmlType="submit" danger >

                                                                        Proceed to Payment
    </Button>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    ) : (

                                                            <div className="fade alert alert-light show">


                                                                <Divider orientation="left"><h5>SELECT OR CREATE A DELIVERY ADDRESS</h5></Divider>


                                                                <Row>
                                                                    <Col sm={12}>


                                                                    </Col>
                                                                    <Col sm={8}>

                                                                        <b>USE A SAVED ADDRESS</b>


                                                                    </Col>
                                                                    <Col sm={4}>

                                                                        <Button color="primary" onClick={this.showModal.bind(this, 100)} style={{ marginBottom: '1rem' }}>
                                                                            Create a new address
                </Button>



                                                                    </Col>

                                                                    <Col sm={12}>
                                                                        <Radio.Group onChange={this.onChange} value={this.state.value}>

                                                                            {

                                                                                JSON.parse(localStorage.getItem('fullUserDetails')).Addresses.map((address, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            {address.AddressType == "Delivery" ? (

                                                                                                <>
                                                                                                    <Radio value={index}>
                                                                                                        {address.AddressType ? (<><b> {address.AddressType} </b><br /></>) : ''}
                                                                                                        {address.AddressLine1 ? (<>{address.AddressLine1}<br /></>) : ''}
                                                                                                        {address.AddressLine2 ? (<>{address.AddressLine2}<br /></>) : ''}
                                                                                                        {address.City ? (<>{address.City}<br /></>) : ''}
                                                                                                        {address.StateProvinceRegion ? (<>{address.StateProvinceRegion}&nbsp;</>) : ''}
                                                                                                        {address.PostZipCode ? (<>{address.PostZipCode}<br /></>) : ''}
                                                                                                        {address.Country ? (<>{address.Country}<br /> </>) : ''}
                                                                                                        {address.Email ? (<><b>Email: </b>{address.Email}<br /></>) : ''}
                                                                                                        {address.Phone ? (<><b>Phone: </b>{address.Phone}<br /></>) : ''}
                                                                                                        {address.Fax ? (<><b>Fax: </b>{address.Fax}<br /></>) : ''}
                                                                                                    </Radio>
                                                                                                    <hr />
                                                                                                </>
                                                                                            ) : ''

                                                                                            }
                                                                                        </>
                                                                                    );
                                                                                })
                                                                            }
                                                                        </Radio.Group>


                                                                    </Col>
                                                                    <Col sm={12}>
                                                                        <Divider orientation="left"><h5>DELIVERY INSTRUCTIONS</h5></Divider>
                                                                        <Form.Item
                                                                            name="signature"
                                                                            label=""
                                                                        >
                                                                            <Checkbox onChange={this.onDeliveryTickChange} >Delivery requires signature   <Popover content={content} className="homeMessage" title="Delivery requires signature">
                                                                                <u>What is this?</u>
                                                                            </Popover></Checkbox>
                                                                        </Form.Item>



                                                                        <Form.Item
                                                                            name="DeliveryIns"
                                                                            label="Delivery Instructions"
                                                                        >
                                                                            <Input placeholder="e.g. Knock at the back door" />
                                                                        </Form.Item>

                                                                    </Col>
                                                                    <Col sm={4}>
                                                                    </Col>
                                                                    <Col sm={4}>

                                                                        <Button type="primary" htmlType="submit" danger >

                                                                            Proceed to Payment
            </Button>
                                                                    </Col>




                                                                </Row>

                                                            </div>
                                                        )
                                                }


                                            </Col>
                                            <Col sm={4}>

                                                {this.state.basketitem.length ? (
                                                    <div className="fade alert alert-light show">
                                                        <Row className="justify-content-md-center">
                                                            <Col sm={12}>
                                                                <h5>ORDER SUMMARY <a href="/cart" className="float-right"><EditOutlined /></a></h5>
                                                            </Col>
                                                            <Col sm={12}><hr /></Col>
                                                            <Col sm={12}>
                                                                <div className="fade alert alert-light show">
                                                                    {this.state.basketitem.length ? (
                                                                        this.state.basketitem.map(item => (
                                                                            <Row>

                                                                                <Col xs={12} md={3}>{
                                                                                    item.ProductImage !== '' ?
                                                                                        <img src={item.ProductImage} width="80" />
                                                                                        : <img src="/images/noimage.jpg" alt="." width="80" />
                                                                                }</Col>
                                                                                <Col xs={12} md={9}><b>{item.ProductName}</b>
                                                                                    <Row>
                                                                                        <Col xs={8} md={6}>Qty: {item.ProductQty}</Col>
                                                                                        <Col xs={4} md={6} className="text-right"><b>{localStorage.getItem('ecurrencyCode')} {(item.ProductQty * item.ProductPrice * localStorage.getItem('ecurrencyratio')).toFixed(2)}</b></Col>
                                                                                    </Row>
                                                                                </Col>

                                                                                <Col md={12}><hr></hr></Col>


                                                                            </Row>
                                                                        )
                                                                        )
                                                                    ) : (
                                                                            <span>
                                                                                <h6>Your cart is empty</h6>
                                                                                <p>Find hundred of quality products at bargain prices everyday!</p>
                                                                            </span>
                                                                        )}

                                                                </div>

                                                            </Col>
                                                            <Col sm={12}><hr /></Col>
                                                            <Col sm={12}>
                                                                <h5>Price Details</h5>
                                                            </Col>
                                                            <Col sm={9} xs={8}>

                                                                Subtotal:
                </Col>
                                                            <Col sm={3} xs={4}>
                                                                <b>{localStorage.getItem('ecurrencyCode')} {this.state.total}</b>
                                                            </Col>


                                                            {
                                                                sessionStorage.getItem('deliverytype') == 1 ? (
                                                                    <>
                                                                        <Col sm={9} xs={8}>

                                                                            Click and Collect:
                                   </Col>
                                                                        <Col sm={3} xs={4}>
                                                                            <b>FREE</b>
                                                                        </Col>
                                                                    </>
                                                                ) : (
                                                                        <>
                                                                            <Col sm={9} xs={8}>

                                                                                Delivery:
                                 </Col>
                                                                            <Col sm={3} xs={4}>
                                                                                {localStorage.getItem('ecurrencyCode')} {parseFloat(sessionStorage.getItem('deliverycost')).toFixed(2)}
                                                                            </Col>
                                                                        </>
                                                                    )
                                                            }

                                                            {this.state.offer != 0 ? (<>
                                                                <Col sm={9} xs={8}>

                                                                    <p class="text-danger">Offer Discount:</p>
                                                                </Col>
                                                                <Col sm={3} xs={4}>
                                                                    <p class="text-danger">
                                                                        {localStorage.getItem('ecurrencyCode')} -{this.state.offer}</p>

                                                                </Col></>) : ''
                                                            }


                                                            <Col sm={12}><hr /></Col>
                                                            <Col sm={9} xs={8}>

                                                                <b>TOTAL:</b>
                                                            </Col>
                                                            <Col sm={3} xs={4}>
                                                                <b>{localStorage.getItem('ecurrencyCode')} {(parseFloat(this.state.total) - this.state.offer + parseFloat(sessionStorage.getItem('deliverycost'))).toFixed(2)}</b>
                                                            </Col>
                                                            <Col sm={12}>&nbsp;</Col>
                                                            <Col sm={2}>

                                                            </Col>


                                                        </Row>

                                                    </div>

                                                ) : (
                                                        <span>

                                                        </span>
                                                    )}
                                            </Col>


                                        </Row>

                                    </Form>

                                    <Modal
                                        visible={visible}
                                        title="NEW DELIVERY ADDRESS INFORMATION"
                                        width="1000px"
                                        onCancel={this.handleCancel}
                                        footer={[
                                            <Button key="back" onClick={this.handleCancel}>
                                                Return
                    </Button>,

                                        ]}
                                    >

                                        <>

                                            <Form layout="vertical"
                                                name="register"
                                                ref={this.formRef}
                                                onFinish={onCreateAddress} >

                                                <Form.Item
                                                    name="SelectAddress"
                                                    extra="Please click below add new button to save the address"
                                                    label="Select an existing address to add"
                                                >

                                                    <Select
                                                        onChange={(val) => this.selectAddress(this, val)}
                                                    >
                                                        {

                                                            JSON.parse(localStorage.getItem('fullUserDetails')).Addresses.map((address, index) => {
                                                                return (
                                                                    <>

                                                                        {
                                                                            <>

                                                                                {address.AddressType == "Billing" ? (

                                                                                    <Option value={index}>
                                                                                        {address.AddressLine1 ? (<>{address.AddressLine1}<br /></>) : ''} {address.AddressLine2 ? (<>{address.AddressLine2}<br /></>) : ''} {address.City ? (<>{address.City}<br /></>) : ''}
                                                                                        {address.StateProvinceRegion ? (<>{address.StateProvinceRegion}&nbsp;</>) : ''}
                                                                                        {address.PostZipCode ? (<>{address.PostZipCode}<br /></>) : ''}
                                                                                        {address.Country ? (<>{address.Country}<br /> </>) : ''}
                                                                                        {address.Email ? (<><b>Email: </b>{address.Email}<br /></>) : ''}
                                                                                        {address.Phone ? (<><b>Phone: </b>{address.Phone}<br /></>) : ''}
                                                                                        {address.Fax ? (<><b>Fax: </b>{address.Fax}<br /></>) : ''}
                                                                                    </Option>

                                                                                ) : ''
                                                                                }
                                                                            </>


                                                                        }
                                                                    </>
                                                                );
                                                            })
                                                        }


                                                    </Select>

                                                </Form.Item>


                                                <Form.Item
                                                    name="AddressType"
                                                    label="Address Type"
                                                    initialValue="Delivery"
                                                    rules={[{ required: true, message: 'Please enter a address type' }]}
                                                >

                                                    <Select   >

                                                        <Option value="Delivery">Delivery</Option>

                                                    </Select>

                                                </Form.Item>


                                                <Form.Item
                                                    name="Address"
                                                    label="Address"
                                                    rules={[{ required: true, message: 'Please enter address' }]}
                                                >
                                                    <Input id="Address" placeholder="Please enter address" />
                                                </Form.Item>

                                                <Form.Item
                                                    name="Address2"
                                                    label="Address 2"
                                                    rules={[{ required: false, message: 'Please enter address 2' }]}
                                                >
                                                    <Input placeholder="Please enter address 2" />
                                                </Form.Item>

                                                <Form.Item
                                                    name="City"
                                                    label="City"
                                                    rules={[{ required: true, message: 'Please enter city' }]}
                                                >
                                                    <Input placeholder="Please enter city" />
                                                </Form.Item>

                                                <Form.Item
                                                    name="StateProvinceRegion"
                                                    label="State / Province / Region"
                                                    rules={[{ required: true, message: 'Please enter state / province / region' }]}
                                                >
                                                    <RegionDropdown
                                                        country={country}
                                                        value={region}
                                                        countryValueType="short"
                                                        classes="ant-input"
                                                        onChange={(val) => this.selectRegion(val)} />
                                                </Form.Item>

                                                <Form.Item
                                                    name="PostZipCode"
                                                    label="Post / Zip Code"
                                                    rules={[{ required: true, message: 'Please enter post / zip code' }]}
                                                >
                                                    <Input placeholder="Please enter post / zip code" />
                                                </Form.Item>

                                                <Form.Item
                                                    name="Country"
                                                    label="Country"
                                                    rules={[{ required: true, message: 'Please enter country' }]}
                                                >
                                                    <CountryDropdown
                                                        value={country}
                                                        valueType="short"
                                                        classes="ant-input"
                                                        onChange={(val) => this.selectCountry(val)} />
                                                </Form.Item>

                                                <Form.Item
                                                    name="Phone"
                                                    label="Phone"
                                                    rules={[{ required: true, message: 'Please enter valid phone' }]}
                                                >
                                                    <Input placeholder="Please enter phone" />
                                                </Form.Item>

                                                <Form.Item
                                                    name="Fax"
                                                    label="Fax"
                                                    rules={[{ message: 'Please enter valid fax' }]}
                                                >
                                                    <Input placeholder="Please enter fax" />
                                                </Form.Item>

                                                <Form.Item
                                                    name="Email"
                                                    label="Email"
                                                    rules={[{ type: 'email', required: true, message: 'Please enter valid email' }]}
                                                >
                                                    <Input placeholder="Please enter email" />
                                                </Form.Item>

                                                <Form.Item>
                                                    <Button type="primary" htmlType="submit" ref="btn" danger >
                                                        {this.state.disabled ? 'Please wait...' : 'Add New Address'}
                                                    </Button>
                                                </Form.Item>
                                            </Form>

                                        </>



                                    </Modal>
                                </div>
                            )
                    }

                })()}
            </>
        )
    }
}

export default Application;