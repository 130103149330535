import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import { Row, Col, Breadcrumb } from 'react-bootstrap';
import { Button, Modal, Radio, Skeleton, message } from 'antd';
import axios from 'axios';
import jwt from 'jsonwebtoken';
import config from 'react-global-configuration';
import {
   CloseOutlined
} from '@ant-design/icons';


const CartApparel = (props) => (

   props.params.ploaded ? (
      <>
         <div className="ps-section--shopping ps-shopping-cart">
            <div className="container">
               <div className="ps-section__header">
                  <h1>Shopping Cart</h1>
               </div>
               <div className="ps-section__content">
                  <div className="table-responsive">

                     {



                        props.params.basketitem.length ? (



                           <table className="table ps-table--shopping-cart ps-table--responsive">
                              <thead>
                                 <tr>
                                    <th>Product name</th>
                                    <th>PRICE</th>
                                    <th>QUANTITY</th>
                                    <th>TOTAL</th>
                                    <th />
                                 </tr>
                              </thead>
                              <tbody>

                                 {
                                    props.params.basketitem.map(item => (
                                       <tr>
                                          <td data-label="Product">
                                             <div className="ps-product--cart">
                                                <div className="ps-product__thumbnail">{
                                                   item.ProductImage !== '' ?
                                                      <img src={item.ProductImage} width="80" />
                                                      : <img src="/images/noimage.jpg" alt="." width="80" />
                                                }</div>
                                                <div className="ps-product__content"><a href={'/product/' + item.ProductName.replace(/ /g, '-').toLowerCase() + '/' + item.ProductID}>{item.ProductName}</a>
                                                   <p><b>Item Code:</b> {item.productdetails.ProductRefNo}</p>

                                                </div>
                                             </div>
                                          </td>
                                          <td className="price" data-label="Price">{localStorage.getItem('ecurrencyCode')} {item.ProductPrice}</td>
                                          <td data-label="Quantity">
                                             <div className="form-group--number">
                                                <button className="up" onClick={props.onQtyIncrese.bind(this, item.ProductID, item.ProductName, item, item.productdetails.MaxOnlineOrderQty, item.productdetails.ProductCategoryID, item.productdetails.offerdetails ? (item.offerdetails[0].OfferProducts.map((prt) => prt.ProductID).join(',')) : '', item.offerdetails.Offer ? (item.offerdetails[0].Offer.PromotionQty) : '', item.offerdetails.Offer ? (item.offerdetails[0].Offer.Price) : '', item.ProductPrice)}>+</button>
                                                <button className="down" onClick={props.onQtyReduce.bind(this, item.ProductID, item.ProductName, item, item.productdetails.MaxOnlineOrderQty, item.productdetails.ProductCategoryID, item.productdetails.offerdetails ? (item.offerdetails[0].OfferProducts.map((prt) => prt.ProductID).join(',')) : '', item.offerdetails.Offer ? (item.offerdetails[0].Offer.PromotionQty) : '', item.offerdetails.Offer ? (item.offerdetails[0].Offer.Price) : '', item.ProductPrice)}>-</button>
                                                <input className="form-control " id={'prodqty' + item.ProductID} min="0" name="form-0-quantity" value={item.ProductQty} type="number" placeholder={1} defaultValue={1} />
                                             </div>
                                          </td>
                                          <td data-label="Total">{localStorage.getItem('ecurrencyCode')} {(item.ProductQty * item.ProductPrice * localStorage.getItem('ecurrencyratio')).toFixed(2)}</td>
                                          <td data-label="Actions"><a onClick={props.onCloseClick.bind(this, item.ProductQty, item.ProductID, item.ProductName, item, item.productdetails.MaxOnlineOrderQty, item.productdetails.ProductCategoryID, item.productdetails.offerdetails ? (item.offerdetails[0].OfferProducts.map((prt) => prt.ProductID).join(',')) : '', item.offerdetails.Offer ? (item.offerdetails[0].Offer.PromotionQty) : '', item.offerdetails.Offer ? (item.offerdetails[0].Offer.Price) : '', item.ProductPrice)}><i className="icon-cross" /></a></td>
                                       </tr>
                                    )
                                    )
                                 }

                              </tbody>
                           </table>


                        ) : (
                              <>
                                 <span>
                                    <h6>Your cart is empty</h6>
                                    <p>Find hundred of quality products at bargain prices everyday!</p>
                                 </span>

                              </>

                           )



                     }


                  </div>
                  <div className="ps-section__cart-actions"><a className="ps-btn" href="/products"><i className="icon-arrow-left" /> Back to Shop</a><a className="ps-btn ps-btn--outline" href="/cart"><i className="icon-sync" /> Update cart</a></div>
               </div>
               <div className="ps-section__footer">
                  <div className="row">
                     <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 ">


                     {props.params.offer != 0 ? (<>
                        <div >
      <figure>
        <figcaption>Discount Details</figcaption>

{

props.params.basketitem.map(item => (
        item.OfferAmount != 0 ? (
            <>

<div className="ps-block__header">
                                 <p>{item.offerdetails[0].Offer.PromotionName} <span>{localStorage.getItem('ecurrencyCode')} -{item.OfferAmount.toFixed(2)}</span></p>
                              </div>


                 
               
            </>
        ) : ''
    ))
}
</figure>
                </div>
</>) : ''
}

                     </div>
                     <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 ">

                        <div>
      <figure>
        <figcaption>Order Type</figcaption>
         
        <div className="form-group">
        <Radio.Group onChange={props.onChange} value={props.params.value}>

{
   JSON.parse(sessionStorage.getItem('setting'))[0].SubOrderTypes.map(st => (
      <>

         {
            st.SubOrderTypeName == 'Click and Collect' && st.IsActive == true ? (

               <Row>
                  <Col sm={9}>
                     <Radio value={1} checked> Click & Collect</Radio>
                  </Col>
                  <Col sm={3}>FREE
</Col>
               </Row>
            ) : ''
         }


         {
            st.SubOrderTypeName == 'Delivery' && st.IsActive == true ? (
               <>

                  <Row>
                     <Col sm={9}>
                        <Radio value={0}>Delivery</Radio>
                     </Col>
                     <Col sm={3}>
                        {
                           props.params.deliveryscheme.map(ds => {

                              //  console.log(ds)

                              if (ds.CurrencyCode == localStorage.getItem('ecurrencythreeCode')) {
                                 const Calculation = ds.Calculation;

                                 if (Calculation == "ByInvoiceAmount") {

                                    const vat = ds.ByInvoiceAmount;
                                    // console.log(ds.ByInvoiceAmount)
                                    // console.log(a)

                                    {
                                       [vat].map((e2, i2) => {



                                          if (props.params.total <= 50 && [vat][i2][50]) {
                                             sessionStorage.setItem('deliverycost', [vat][i2][50]);

                                          } else if (props.params.total <= 100 && [vat][i2][100]) {
                                             sessionStorage.setItem('deliverycost', [vat][i2][100]);

                                          } else if (props.params.total <= 150 && [vat][i2][150]) {
                                             sessionStorage.setItem('deliverycost', [vat][i2][150]);

                                          } else if (props.params.total <= 750 && [vat][i2][750]) {
                                             sessionStorage.setItem('deliverycost', [vat][i2][750]);

                                          } else {
                                             sessionStorage.setItem('deliverycost', [vat][i2]['Above']);

                                          }


                                       })
                                    }


                                    sessionStorage.setItem('MinimumInvoiceOrder', ds.MinimumInvoiceOrder);


                                 } else {
                                    //to do other methods
                                 }

                              } else {
                                 //to do other countries 
                              }
                           })
                        }


                        {localStorage.getItem('ecurrencyCode')}  {parseFloat(sessionStorage.getItem('deliverycost')).toFixed(2)}

                     </Col>
                  </Row>
                  <hr />
               </>
            ) : ''
         }


      </>
   )
   )
}




</Radio.Group>
        </div>
      </figure>   
                           
                        
                        </div>


                     </div>
                     <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 ">
                        <div className="ps-block--shopping-total">
                           <div className="ps-block__header">
                              <p>Subtotal <span> {localStorage.getItem('ecurrencyCode')} {props.params.total}</span></p>
                           </div>

                           {props.params.offer != 0 ? (<>
                              <div className="ps-block__header">
                                 <p>Offer Discount <span>{localStorage.getItem('ecurrencyCode')} -{props.params.offer}</span></p>
                              </div>
                           </>) : ''
                           }

                           {props.params.value == 0 ? <>
                              <div className="ps-block__header">
                                 <p>Delivery <span>{localStorage.getItem('ecurrencyCode')} {parseFloat(sessionStorage.getItem('deliverycost')).toFixed(2)}</span></p>
                              </div>
                           </> : ''}

                           <div className="ps-block__content">
                               
                              <h3>Total <span>{localStorage.getItem('ecurrencyCode')} {(parseFloat(props.params.total) - props.params.offer + (props.params.value == 0 ? parseFloat(sessionStorage.getItem('deliverycost')) : 0)).toFixed(2)}</span></h3>
                           </div>
                        </div><a className="ps-btn ps-btn--fullwidth" onClick={props.onProceedCheckout.bind(this)}>Proceed to checkout</a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   ) : (
         <>
            <Skeleton active avatar paragraph={{ rows: 4 }} />

         </>

      )

);
export default CartApparel;
